/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export { wrapRootElement } from "./src/services/wrap-root-element"

// export { replaceHydrateFunction } from "./src/services/hydrate"

export const onClientEntry = () => {

  // TODO: start analytics

  window.onload = () => {
    
    
  }
}