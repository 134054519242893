import React, { useState, useContext } from "react"
import { IntlProvider, FormattedMessage } from "react-intl"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import en_us from "./translations/en-us.json"
import es_us from "./translations/es-us.json"
import pt_us from "./translations/pt-us.json"

const _messages = { 
  "en-us": en_us, 
  "es-us": es_us, 
  "pt-us": pt_us
}

const getConfigOnLoad = () => {
  const prefix = typeof window !== `undefined` && window.location.pathname.split('/')[1]

  switch (prefix) {
    case 'es':
      return { locale: 'es-us', messages: es_us }
    case 'pt':
      return { locale: 'pt-us', messages: pt_us }
    default:
      return { locale: 'en-us', messages: en_us }
  }
}

export const IntlContext = React.createContext()

export const IntlProviderWrapper = ({ children }) => {

  const [intlConfig, setIntlConfig] = useState(getConfigOnLoad())
  const [alts, setAlts] = useState([])

  const changeLocale = (loc) => {
    if (intlConfig.locale !== loc) {
      setIntlConfig({
        locale: loc,
        messages: _messages[loc]
      })
    }
  }

  return (
    <IntlContext.Provider value={{ locale:intlConfig.locale, changeLocale, alts, setAlts }}>
      <IntlProvider key={intlConfig.locale} locale={intlConfig.locale} messages={intlConfig.messages}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  )
}

export const LocaleSwitcher = ({ textList = false, isRight = false, isDark = false, globeOnly = false }) => {
  const { locale, alts } = useContext(IntlContext)

  if (textList) {
    return (
      <>
        {alts.map((alt, i) => {
          return (
            <>
              {(alts.length > 1 && i > 0) && <span>{` | `}</span>}
              <Link to={alt.url} key={i}>
                {alt.lang === 'en-us' && <span>en</span>}
                {alt.lang === 'es-us' && <span>es</span>}
                {alt.lang === 'pt-us' && <span>pt</span>}
              </Link>
            </>
          )
        })}
      </>
    )
  } else {
    let buttonClasses = ['button']
    if (isDark) buttonClasses.push('is-dark')

    return (
      <div className={isRight?"dropdown is-hoverable is-right":"dropdown is-hoverable"}>
        <div className="dropdown-trigger">
          <button className={buttonClasses.join(' ')} aria-haspopup="true" aria-controls="dropdown-locale-switcher">
            <span className="icon">
              <FontAwesomeIcon icon="globe" />
            </span>
            {!globeOnly && <span>
                <FormattedMessage id="locale_switcher.language" />
              </span>
            }
            <span className="icon is-small">
              <FontAwesomeIcon icon="angle-down" />
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-locale-switcher" role="menu">
          <div className="dropdown-content">
            {alts.map((alt, i) => {
              return <Link to={alt.url} key={i} className={alt.lang===locale?"dropdown-item is-active":"dropdown-item"}>
                  {alt.lang === 'en-us' && <span>English</span>}
                  {alt.lang === 'es-us' && <span>Español</span>}
                  {alt.lang === 'pt-us' && <span>Português</span>}
              </Link>
            })}
          </div>
        </div>
      </div>
    )
  }
}
