// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-404-js": () => import("./../../../src/components/pages/404.js" /* webpackChunkName: "component---src-components-pages-404-js" */),
  "component---src-components-pages-about-js": () => import("./../../../src/components/pages/about.js" /* webpackChunkName: "component---src-components-pages-about-js" */),
  "component---src-components-pages-contact-js": () => import("./../../../src/components/pages/contact.js" /* webpackChunkName: "component---src-components-pages-contact-js" */),
  "component---src-components-pages-homepage-index-js": () => import("./../../../src/components/pages/homepage/index.js" /* webpackChunkName: "component---src-components-pages-homepage-index-js" */),
  "component---src-components-pages-landing-page-js": () => import("./../../../src/components/pages/landing-page.js" /* webpackChunkName: "component---src-components-pages-landing-page-js" */),
  "component---src-components-pages-linkinbio-js": () => import("./../../../src/components/pages/linkinbio.js" /* webpackChunkName: "component---src-components-pages-linkinbio-js" */),
  "component---src-components-pages-map-my-customers-index-js": () => import("./../../../src/components/pages/map-my-customers/index.js" /* webpackChunkName: "component---src-components-pages-map-my-customers-index-js" */),
  "component---src-components-pages-privacy-js": () => import("./../../../src/components/pages/privacy.js" /* webpackChunkName: "component---src-components-pages-privacy-js" */),
  "component---src-components-pages-products-services-js": () => import("./../../../src/components/pages/products-services.js" /* webpackChunkName: "component---src-components-pages-products-services-js" */),
  "component---src-components-pages-qr-code-generator-js": () => import("./../../../src/components/pages/qr-code-generator.js" /* webpackChunkName: "component---src-components-pages-qr-code-generator-js" */),
  "component---src-components-pages-terms-js": () => import("./../../../src/components/pages/terms.js" /* webpackChunkName: "component---src-components-pages-terms-js" */),
  "component---src-pages-early-access-20-percent-off-js": () => import("./../../../src/pages/early-access-20-percent-off.js" /* webpackChunkName: "component---src-pages-early-access-20-percent-off-js" */),
  "component---src-pages-early-access-demo-js": () => import("./../../../src/pages/early-access-demo.js" /* webpackChunkName: "component---src-pages-early-access-demo-js" */),
  "component---src-pages-early-access-free-postcard-design-js": () => import("./../../../src/pages/early-access-free-postcard-design.js" /* webpackChunkName: "component---src-pages-early-access-free-postcard-design-js" */),
  "component---src-pages-early-access-new-year-new-customers-js": () => import("./../../../src/pages/early-access-new-year-new-customers.js" /* webpackChunkName: "component---src-pages-early-access-new-year-new-customers-js" */),
  "component---src-pages-early-access-send-postcards-js": () => import("./../../../src/pages/early-access-send-postcards.js" /* webpackChunkName: "component---src-pages-early-access-send-postcards-js" */),
  "component---src-pages-early-access-special-offer-js": () => import("./../../../src/pages/early-access-special-offer.js" /* webpackChunkName: "component---src-pages-early-access-special-offer-js" */),
  "component---src-pages-free-postcard-marketing-strategy-report-js": () => import("./../../../src/pages/free-postcard-marketing-strategy-report.js" /* webpackChunkName: "component---src-pages-free-postcard-marketing-strategy-report-js" */)
}

