let _accessToken = null
let _currentUser = null

export function setAuth(user, accessToken) {
  _currentUser = user
  _accessToken = accessToken
}

export function clearAuth() {
  _currentUser = null
  _accessToken = null
}

export function updateCurrentUser(user) {
  Object.assign(_currentUser, user);
}

export function isLoggedIn() {
  return !!_currentUser
}

export function currentUser() {
  return _currentUser
}

export function accessToken() {
  return _accessToken
}

// export function refresh() {

// }