import React from "react"

// Apollo
import { ApolloProvider } from "@apollo/client"
import { client } from "../apollo/client"

// Intl
import { IntlProviderWrapper } from "../intl"

export const wrapRootElement = ({ element }) => (
  <IntlProviderWrapper>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </IntlProviderWrapper>
)
